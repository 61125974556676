import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiracleMatic from "../../contracts/MiracleMatic.json";
import getWeb3 from "../../getWeb3";
// const defaultAmountArr = ['50','100','250','500','1000'];
const defaultAmountArr = ['50','200','400','800','1600'];


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      _userUplineIncome:0,
      _userDownlineIncome:0,
      package_index:0,
      geteligibleFor:0,
      userExtraBonus:0
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = MiracleMatic.networkId;
        const instance = new web3.eth.Contract(
          MiracleMatic.abi,
          deployedNetwork && MiracleMatic.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:MiracleMatic.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;
    
    // console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();    
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
    
    userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
    userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
    let user_referer = '---------';
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
    }

    
    
    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    TotalBonus = parseFloat(TotalBonus).toFixed(5);
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
    let totalDeposits = await contract.methods.totalDeposits().call();
    totalDeposits = parseFloat(totalDeposits).toFixed(5);
    let totalInvested = await contract.methods.totalInvested().call();
    totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
    totalInvested = parseFloat(totalInvested).toFixed(5);
    let totalUsers = await contract.methods.totalUsers().call();
    
    //let geteligibleFor = await contract.methods.geteligibleFor(accounts).call();
    let geteligibleFor = this.state.web3.utils.fromWei(userInfo.levelIncome, 'ether');
    geteligibleFor = parseFloat(geteligibleFor).toFixed(5);
    
    let userExtraBonus = await contract.methods.userExtraBonus(accounts).call();
    userExtraBonus = this.state.web3.utils.fromWei(userExtraBonus, 'ether');
    userExtraBonus = parseFloat(userExtraBonus).toFixed(5);

    let balance = TotalBonus;
    let adminFee = balance*this.state.adminFee_percentage/100;
    balance = balance - adminFee;


    let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
    let reinvest_percentage = getEligibleWithdrawal.reivest;
    let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

    let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
    let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

    // let GetDownlineIncomeByUserId = await contract.methods.getCurrentDownlineIncomeByUserId(accounts).call();
    // GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
    // GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

    // let GetUplineIncomeByUserId = await contract.methods.getCurrentUplineIncomeByUserId(accounts).call();
    // GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    // GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

    let GetUplineIncomeByUserId = await contract.methods._userUplineIncome(accounts).call();
    GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
    GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

    let singleDownlineBonus = this.state.web3.utils.fromWei(userInfo.singleDownlineBonus, 'ether');
    let GetDownlineIncomeByUserId = singleDownlineBonus - singleDownlineBonusTaken;
    GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);
    

     
    this.setState({ 
      userInfo,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      totalInvested,
      totalUsers,
      //networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
      user_referer:user_referer,
      balance,
      adminFee,
      reinvest_percentage,
      withdrwal_percentage,
      GetUplineIncomeByUserId,
      GetDownlineIncomeByUserId,
      geteligibleFor,
      userExtraBonus,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
      let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
      let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
      let communityLevels = [];
      let upline_users = [];
      let downline_users = [];
      let current_user = accounts;
      let userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=uplineCount;i++){          
        if(current_user == userInfo.singleUpline) continue;
        current_user = userInfo.singleUpline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
      }
      upline_users.sort((a, b) => b.level>a.level? 1 : -1);
      upline_users.map(function(val, index){
        communityLevels.push(val);
      });
      let currentUserInfo = await contract.methods.users(accounts).call();
      let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
      let income = (investment/100).toFixed(5);
      income = (0).toFixed(5);
      investment = parseFloat(investment).toFixed(5);
      let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
      communityLevels.push(main_user);
      current_user = accounts;
      userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=downlineCount;i++){
        if(current_user == userInfo.singleDownline) continue;
        current_user = userInfo.singleDownline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
      }
      downline_users.map(function(val, index){
        communityLevels.push(val);
      });

      let sponsorList = [];
      let count = 0;
      // for(let i=0;i<10;i++){
      //   let referral_stage = await contract.methods.referral_stage(accounts,i).call();
      //    let requiredDirect = await contract.methods.requiredDirect(i).call();
      //   let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
       
      //   let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
      //   _investment = parseFloat(_investment).toFixed(5);
      //   percentage_amount = parseFloat(percentage_amount).toFixed(5);
      //   let _noOfUser = referral_stage._noOfUser;
        
      //   if(i == 0){
      //     count = _noOfUser;
      //   }
      //   let status = '';
      //   let requireText = '';
      //   if(count<requiredDirect){
      //     status = 'Unqualified';
      //     requireText = 'require '+requiredDirect + ' direct';
      //   }
      //   sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      // }
      
      //this.setState({sponsorList});
      this.setState({communityLevels, sponsorList});
      // console.log(communityLevels);      
      // console.log(sponsorList);       
    });     
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});          

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address, this.state.package_index).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          if(invest.status){
            this.props.enqueueSnackbar("invest Success!",{ variant: 'success' });  
          }else{
            this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });  
          }
          this.setState({loading:false});
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});      

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );

      if(withdrawal.status){
        this.props.enqueueSnackbar("Withdrawl Success!",{ variant: 'success' });  
      }else{
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });  
      }
      this.setState({loading:false});
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame contractAddress={this.state.contractAddress}>        
        {this.state.loading ? (
         <div className="loader-container">
          <div className="spinner"> </div>
        </div>
        ) : (null)}

      <div className="left_right_holder">
        <div className="left_part">
          <div className="logo_box d-flex align-items-center">
            <div className="mr-auto">
              <img className="light_logo logo" src="img/logo.png" alt="" />
              <img className="dark_logo logo" src="img/dark-logo.png" alt="" />
            </div>
            <div>
              <button className="dark-button mode_btn"><img src="img/moon.png" alt="" /> </button>
              <button className="light-button mode_btn"><img src="img/sun.png" alt="" /></button>
            </div>
          </div>
          <div className="id_box">
            ID - <span>{this.state.YourAddress}</span>
          </div>
          <div className="set_up_wallet">
            <h4>Upline Income</h4>
            <h3>{this.state.GetUplineIncomeByUserId} MATIC</h3>
            <img src="img/matic.png" alt="" />
          </div>
          <div className="set_up_wallet">
            <h4>Downline Income</h4>
            <h3>{this.state.GetDownlineIncomeByUserId} MATIC</h3>
            <img src="img/matic.png" alt="" />
          </div>
          <div className="set_up_wallet">
            <h4>Extra Bouns</h4>
            <h3>{this.state.userExtraBonus} MATIC</h3>
            <img src="img/matic.png" alt="" />
          </div>
          <div className="set_up_wallet">
            <h4>Level Income</h4>
            <h3>{this.state.geteligibleFor} MATIC</h3>
            <img src="img/matic.png" alt="" />
          </div>
          <div className="set_up_wallet">
            <h4>Referred by</h4>
            <h3>{this.state.user_referer}</h3>
            <img src="img/matic.png" alt="" />
          </div>
        </div>
        <div className="right_part">
          <div className="fifty_part_holder">
            <div className="fifty_part">
              <div className="box_bg p-4">
                <div className="heading_text mb-4">
                  <h4><img src="img/down.png" alt="" />Withdraw</h4>
                </div>
                  <div className="Reinvestment_bg">
                    <p className="mb-0">Reinvestment percentage : <span>{this.state.reinvest_percentage}%</span></p>
                    <p className="mb-0">Withdrawable percentage : <span>{this.state.withdrwal_percentage}% </span></p>
                  </div>
                  <div className="form-group">
                    <label>Available MATIC</label>
                    <input className="cus_input" type="text" readOnly value={this.state.balance || 0} />
                  </div>
                  <div className="form-group">
                    <label>Reinvestment Amount (MATIC)</label>
                    <input className="cus_input" type="text" readOnly value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5) || 0} />
                  </div>
                  <div className="form-group">
                    <label>Withdrawable Amount (MATIC)</label>
                    <input className="cus_input" type="text" readOnly value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5) || 0} />
                  </div>
                  <div className="mt-4"> 
                    <button disabled={this.state.loading} onClick={this.doWithdrawal} className="dark_btn">Submit</button>
                  </div>
              </div>
            </div>
            <div className="fifty_part">
              <div className="pl-3 pt-3">
                <div className="heading_text mb-4">
                  <h4><img src="img/up.png" alt="" />Join Now</h4>
                </div>
                <ul className="trx_btn mb-2">
                  {defaultAmountArr.map((amount,key) => {return(
                    <li key={key}>
                      <button className={this.state.amountBNB==amount?'active':'inactive'}
                        onClick={e => this.setState({amountBNB:amount,package_index:key})}>
                        {amount+''}
                      </button>
                    </li>
                    )
                  })}
                </ul>
                <div className="form-group">
                    {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                      <input className="cus_input" type="text" placeholder="Sponsor Address"
                        readOnly
                        value={this.state.user_referer || ''}
                        onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                      />
                    ):(
                      <input className="cus_input" type="text" placeholder="Sponsor Addresss"
                        value={this.state.sponsor_address || ''}
                        onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                      />
                    )}
                </div>
                <div className="text-left mb-4"> 
                  <button disabled={this.state.loading} onClick={this.doJoinNow} className="dark_btn">Join Now</button>
                </div>
              <p className="font-light">Address</p>
              <div className="d-flex d-flex align-items-center">
                <CopyToClipboard text={`https://miraclematic.com/${this.state.YourAddressfull}`}
                    onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                    }>
                    <button title="Copy Your Referal Link" className="copy_btn mr-2">
                    <img src="img/copy.png" alt="" />
                  </button>
                </CopyToClipboard> 
                  <div className="word-break">https://<span>miraclematic</span>.com/{this.state.YourAddress}</div>
              </div>

              

              <div className="mt-5 mb-5">
                 <a target={'_blank'} href={`https://polygonscan.com/address/${this.state.contractAddress}`} className="dark_btn gradient_btn">Verified Contract</a>
              </div>
              </div>
            </div>
          </div>
          <div className="teble-box">
            <ul className="nav nav-tabs mt-3" role="tablist">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" data-toggle="tab" href="#Community_Level" role="tab" aria-selected="true">Community Level</a>
              </li>
              {/* <li className="nav-item" role="presentation">
                <a className="nav-link" data-toggle="tab" href="#sponsor_List" role="tab" aria-selected="false">My sponsor List</a>
              </li> */}
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="Community_Level" role="tabpanel">
                <div className="teble-responsive table_scroll">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>User ID</th>
                      <th>Investment</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.communityLevels.length ? (
                      this.state.communityLevels.length>0 ? (
                        this.state.communityLevels.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td>{val.levelText}</td>
                              <td>{val.username}</td>
                              <td>{val.investment} MATIC</td>
                              {/* <td>{val.income} MATIC</td> */}
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )} 
                  </tbody>
                </table>
                </div>
              </div>
              <div className="tab-pane fade" id="sponsor_List" role="tabpanel">
                <div className="teble-responsive table_scroll">
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Level </th>
                        <th> Count </th>
                        <th> Invest Amount </th>
                        <th> Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sponsorList.length ? (
                        this.state.sponsorList.length>0 ? (
                          this.state.sponsorList.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            return (
                              <tr key={`sl${index}`} className={class_name}>
                                <td> Level-{val.level} <small style={{color:'#fdbd3c'}}>{val.requireText?(`${val.requireText}`):(null)}</small></td>
                                <td>{val._noOfUser}</td>
                                <td>{val._investment} MATIC</td>
                                <td>{val.percentage_amount} MATIC 
                                {/* {val.status?(`(${val.status})`):(null)} */}
                                </td>
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                        <tr>
                          <td colSpan="4" className="text-center">No Data Available!</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="watermark">MiracleMatic</div>

        
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));