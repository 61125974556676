import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer className="pt-5 pb-5">
          <div className="container">
            <p className="copy_text">&copy; 2021 MiracleMatic All Rights Reserved. </p>
          </div>
        </footer>
      );
    }
  }

  export default Footer;